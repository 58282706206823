import React from 'react';
import { StaffDetails } from '@think-zambia-foundation/components';

export function MilimoStaffDetails() {
  return (
    <StaffDetails claims={[
      'billAdmin',
      'billUser',
      'invoiceAdmin',
      'invoiceUser',
      'jobApplicationAdmin',
      'jobApplicationUser',
      'organizationAdmin',
      'staffAdmin',
      'staffPaymentAdmin',
    ]}
    />
  );
}
