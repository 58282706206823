/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Grid, Toolbar } from '@mui/material';
import {
  useAuth,
  useCore,
  GET_STAFF_BY_UID,
  GET_ASSESSMENTS_BY_REFERENCE_ID,
  GET_ASSESSMENTS_BY_APPROVER_ID,
  AssessmentType,
} from '@think-zambia-foundation/context';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CollapsibleCard } from '@think-zambia-foundation/components';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import {
  openCreateAssessmentDialog,
} from '../../reducers';
import {
  CreateAssessmentDialog,
  AssessmentApprovals,
  AssessmentsList,
} from '../../components';

export function Assessments() {
  const [assessments, setAssessments] = useState();
  const [assessmentApprovals, setAssessmentApprovals] = useState();

  const {
    createAssessmentData,
    creatingAssessment,
  } = useCore();

  const dispatch = useDispatch();

  const { coreClient } = useCore();

  const {
    currentUser,
  } = useAuth();

  const [fetchStaffByUidQuery, {
    data: fetchStaffByUidData,
  }] = useLazyQuery(GET_STAFF_BY_UID, {
    client: coreClient,
  });

  const [fetchAssessmentsByReferenceId, {
    data: fetchAssessmentsData,
    loading: fetchingAssessments,
  }] = useLazyQuery(GET_ASSESSMENTS_BY_REFERENCE_ID, {
    client: coreClient,
  });

  const [fetchAssessmentsByApproverId, {
    data: fetchAssessmentsByApproverIdData,
    loading: fetchingAssessmentsByApproverId,
  }] = useLazyQuery(GET_ASSESSMENTS_BY_APPROVER_ID, {
    client: coreClient,
  });

  // Get the staff account
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      fetchStaffByUidQuery({
        variables: {
          uid: currentUser.uid,
        },
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (fetchStaffByUidData?.staffByUid?.staffId) {
      fetchAssessmentsByReferenceId({
        variables: {
          referenceId: fetchStaffByUidData?.staffByUid?.staffId,
        },
      });
      fetchAssessmentsByApproverId({
        variables: {
          approverId: fetchStaffByUidData?.staffByUid.staffId,
        },
      });
    }
  }, [fetchStaffByUidData, createAssessmentData]);

  useEffect(() => {
    if (fetchAssessmentsData) {
      setAssessments(fetchAssessmentsData?.assessmentsByReferenceId);
    }
  }, [fetchAssessmentsData]);

  useEffect(() => {
    if (fetchAssessmentsByApproverIdData?.assessmentsByApproverId) {
      setAssessmentApprovals(
        fetchAssessmentsByApproverIdData?.assessmentsByApproverId?.filter(
          (assessment: AssessmentType) => assessment?.assessmentStatus?.toLowerCase() === 'submitted',
        ),
      );
    }
  }, [fetchAssessmentsByApproverIdData]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <CollapsibleCard
            title="Assessments"
            loading={creatingAssessment || fetchingAssessments || fetchingAssessmentsByApproverId}
            actions={[
              {
                key: '9288a416d02e4f138186fc95639eda13',
                callback: () => dispatch(openCreateAssessmentDialog()),
                icon: <AddIcon />,
                disableCard: !currentUser || !fetchStaffByUidData?.staffByUid?.managerId,
              },
            ]}
          >
            <AssessmentsList assessments={assessments || []} />
          </CollapsibleCard>
        </Grid>
        <Grid item xs={12}>
          <Toolbar />
        </Grid>
        {assessmentApprovals && (
        <Grid item xs={12}>
          <CollapsibleCard
            title="Assessment Approvals"
          >
            <AssessmentApprovals assessmentApprovals={assessmentApprovals} />
          </CollapsibleCard>
        </Grid>
        )}
      </Grid>
      {fetchStaffByUidData?.staffByUid && (
      <CreateAssessmentDialog
        referenceId={fetchStaffByUidData?.staffByUid?.staffId}
        approverId={fetchStaffByUidData?.staffByUid?.managerId}
      />
      )}
    </>
  );
}
