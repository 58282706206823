/* eslint-disable react/prop-types */
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  useCore, useAuth, InvoiceType, openCreateInvoiceDialog,
} from '@think-zambia-foundation/context';
import {
  TableList,
  CollapsibleCard,
  CreateInvoiceDialog,
} from '@think-zambia-foundation/components';
import { Grid, Toolbar } from '@mui/material';
import { useDispatch } from 'react-redux';

export interface InvoiceListProps {
  invoices: InvoiceType[]
}

export function InvoiceList(
  { invoices }: InvoiceListProps,
) {
  return (
    <TableList
      data={invoices}
      tableMetadata={{
        href: '/invoice',
        tableKey: 'invoiceId',
        cells: [
          {
            key: 'invoiceId',
            header: 'Invoice Number',
            variant: 'id',
          }, {
            key: 'status',
            header: 'Status',
            variant: 'default',
          }, {
            key: 'createdDate',
            header: 'Created On',
            variant: 'default',
          }, {
            key: 'departmentId',
            header: 'Department',
            value(data) { return `${data?.department?.name || ''}`; },
            variant: 'default',
          }, {
            key: 'ownerId',
            header: 'Created By',
            value(data) { return `${data?.owner?.firstName || ''} ${data?.owner?.lastName || ''}`; },
            variant: 'name',
          }],
      }}
      elevation={0}
    />
  );
}

export function InvoiceListController() {
  const dispatch = useDispatch();

  const {
    fetchingInvoices,
    errorFetchingInvoices,
    fetchInvoicesData,
  } = useCore();

  const {
    currentUser,
  } = useAuth();

  const { hasPermissionToClaim } = useAuth();
  if (!currentUser || !hasPermissionToClaim('invoiceUser')) return <>You do not have permissions to this page. contact the system admin</>;
  if (errorFetchingInvoices) return <>Error Fetching Staff List. Please Try Again</>;

  const invoices = fetchInvoicesData?.invoices?.filter(
    (invoice) => invoice?.owner?.uid === currentUser?.uid && invoice?.status === 'CREATED',
  );
  const approvals = fetchInvoicesData?.invoices?.filter(
    (invoice) => currentUser?.uid === invoice?.department?.headOfDepartment?.uid && invoice?.status === 'SUBMITTED',
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <CollapsibleCard
          title="My Invoices"
          actions={[
            {
              key: 'a2b6d46a202b484c8fe14fa7dcb98cfc',
              callback: () => dispatch(openCreateInvoiceDialog()),
              icon: <AddIcon />,
            },
          ]}
          loading={fetchingInvoices}
        >
          <InvoiceList invoices={invoices || []} />
        </CollapsibleCard>
      </Grid>
      <Grid item xs={12}>
        <Toolbar />
      </Grid>
      {approvals && (
      <Grid item xs={12}>
        <CollapsibleCard
          title="Invoice Approvals"
          loading={fetchingInvoices}
        >
          <InvoiceList invoices={approvals || []} />
        </CollapsibleCard>
      </Grid>
      )}
      <CreateInvoiceDialog />
    </Grid>
  );
}
