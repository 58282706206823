import React from 'react';
import { TableList } from '@think-zambia-foundation/components';
import { AssessmentType } from '@think-zambia-foundation/context';

export function AssessmentsList({ assessments }: { assessments: AssessmentType[] }) {
  return (
    <TableList
      tableMetadata={{
        href: '/assessment',
        tableKey: 'assessmentId',
        cells: [{
          key: 'assessmentDate',
          header: 'Assessment Date',
          variant: 'id',
          value(data) { return (new Date(parseInt(data.assessmentDate, 10))).toLocaleDateString('en-UK'); },
        }, {
          key: 'assessmentStatus',
          header: 'Status',
          variant: 'name',
          value(data) { return data.assessmentStatus.toLowerCase(); },
        }, {
          key: 'rating',
          header: 'Rating',
          variant: 'name',
        }, {
          key: 'managerAssessmentRating',
          header: 'Manager Rating',
          value(data) {
            return data.managerAssessment && data.managerAssessment.rating
              ? data.managerAssessment.rating
              : '-';
          },
          variant: 'name',
        }],
      }}
      data={assessments || []}
      elevation={0}
    />
  );
}

export function AssessmentApprovals(
  { assessmentApprovals }: { assessmentApprovals: AssessmentType[] },
) {
  return (
    <TableList
      tableMetadata={{
        href: '/assessment',
        tableKey: 'assessmentId',
        cells: [{
          key: 'assessmentDate',
          header: 'Assessment Date',
          variant: 'id',
          value(data) { return (new Date(parseInt(data.assessmentDate, 10))).toLocaleDateString('en-UK'); },
        }, {
          key: 'referenceId',
          header: 'Employee',
          variant: 'name',
          value(data) { return `${data?.staff?.firstName} ${data?.staff?.lastName}`; },
        }, {
          key: 'assessmentStatus',
          header: 'Status',
          variant: 'name',
          value(data) { return data?.assessmentStatus?.toLowerCase(); },
        }, {
          key: 'rating',
          header: 'Rating',
          variant: 'name',
        }],
      }}
      data={assessmentApprovals || []}
      elevation={0}
    />
  );
}
