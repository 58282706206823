/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  FormControl, Grid, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCoreDialog, CoreDialogProvider, useCore } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { closeUpdateAssessmentDialog, MilimoSlice } from '../../reducers';

const ratings = [
  {
    label: '1 Poor',
    value: 1,
  },
  {
    label: '2 Average',
    value: 2,
  },
  {
    label: '3 Good',
    value: 3,
  },
  {
    label: '5 Very Good',
    value: 5,
  },
];

export interface UpdateAssessmentDialogProps {
  referenceId: string
}

function Wrapped({ referenceId }: UpdateAssessmentDialogProps) {
  const {
    updateAssessment,
    updatingAssessment,
    updateAssessmentData,
  } = useCore();

  const dispatch = useDispatch();

  const {
    dialogOpen,
    setDialogOpen,
  } = useCoreDialog();

  const updateAssessmentDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.updateAssessmentDialogOpen,
  );

  const [rating, setRating] = useState<number>(1);
  const [completedTasks, setCompletedTasks] = useState<string | undefined>();
  const [incompleteTasks, setIncompleteTasks] = useState<string | undefined>();
  const [improvementOpportunities, setImprovementOpportunities] = useState<string | undefined>();

  useEffect(() => {
    if (updateAssessmentData) {
      dispatch(closeUpdateAssessmentDialog());
    }
    // eslint-disable-next-line
  }, [updateAssessmentData]);

  const handleSubmitUpdateAssessment = () => {
    updateAssessment({
      assessmentId: referenceId,
      rating,
      completedTasks,
      incompleteTasks,
      improvementOpportunities,
    });
  };

  useEffect(() => {
    setDialogOpen(updateAssessmentDialogOpen);
    // eslint-disable-next-line
  }, [updateAssessmentDialogOpen]);

  const CHARACTER_LIMIT = 250;

  return (
    <CoreDialog
      modalTitle="Update Assessment"
      submitAction={() => handleSubmitUpdateAssessment()}
      onClose={() => dispatch(closeUpdateAssessmentDialog())}
      dialogOpen={dialogOpen}
      loading={updatingAssessment}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <TextField
              label="Complete Tasks"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={(event) => setCompletedTasks(event.target.value)}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Incomplete Tasks"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={(event) => setIncompleteTasks(event.target.value)}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Improvement opportunities"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={(event) => setImprovementOpportunities(event.target.value)}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              // TODO: This should be an autocomplete to avoid the parseInt issue
              fullWidth
              select
              label="Add Rating"
              type="number"
              value={rating}
              onChange={(event) => setRating(parseInt(event.target.value, 10))}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              {ratings.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export default function UpdateAssessmentDialog({
  referenceId,
}: UpdateAssessmentDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped referenceId={referenceId} />
    </CoreDialogProvider>
  );
}
