/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { Grid, CardContent, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {
  useAuth,
  useChangeHistory,
  useCore,
  AssessmentType,
  GET_ASSESSMENT_BY_ASSESSMENT_ID,
} from '@think-zambia-foundation/context';
import { SmartTypography, CollapsibleCard, Loading } from '@think-zambia-foundation/components';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  openCreateAssessmentDialog,
  openUpdateAssessmentDialog,
} from '../../reducers';
import { CreateAssessmentDialog } from '../../components';
import UpdateAssessmentDialog from '../../components/UpdateAssessmentDialog/UpdateAssessmentDialog';

export interface AssessmentDetailsProps {
  assessment: AssessmentType
}

export function Wrapped(
  { assessment }: AssessmentDetailsProps,
) {
  const {
    fetchStaffByUidData,
  } = useCore();

  const dispatch = useDispatch();
  const disableAddManagerAssessment = !(!assessment?.managerAssessment
    && !!fetchStaffByUidData?.staffByUid
    && !!assessment?.approverId
    && fetchStaffByUidData?.staffByUid?.staffId === assessment?.approverId);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <CollapsibleCard
          title="Assessment Details"
          actions={[
            {
              key: 'be05c472f10b40e7a3237e40df7c0103',
              callback: () => dispatch(openCreateAssessmentDialog()),
              icon: <PlaylistAddCheckIcon />,
              text: 'Create Assessment',
              disableCard: disableAddManagerAssessment,
            },
            {
              key: 'be05c472i20b40e7a3237e40df7c0103',
              callback: () => dispatch(openUpdateAssessmentDialog()),
              icon: <EditTwoToneIcon />,
              text: 'Update Assessment',
            },
          ]}
        >
          <CardContent>
            <SmartTypography
              text={`${assessment?.staff?.firstName || ''} ${assessment?.staff?.lastName || ''}`}
              caption="Name"
            />
            <SmartTypography
              text={`${assessment.rating}`}
              caption="Rating"
            />
            {assessment?.managerAssessment?.rating && (
              <SmartTypography
                text={`${assessment?.managerAssessment?.rating || ''}`}
                caption="Manager Rating"
              />
            )}
            <SmartTypography
              text={assessment?.assessmentStatus}
              caption="Assessment Status"
              variant="name"
            />
          </CardContent>
        </CollapsibleCard>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CollapsibleCard
              title="Completed Tasks"
            >
              <CardContent>
                <React.Fragment key="paragraph">
                  <Typography noWrap variant="caption" color="primary" style={{ textTransform: 'capitalize' }}>
                    Staff Comments
                  </Typography>
                  <Typography variant="body2" gutterBottom component="span">
                    <div
                      dangerouslySetInnerHTML={{ __html: assessment?.completedTasks.replaceAll('\n', '<br />') }}
                    />
                  </Typography>
                </React.Fragment>
                {assessment?.managerAssessment?.completedTasks && (
                <React.Fragment key="paragraph">
                  <Typography noWrap variant="caption" color="primary" style={{ textTransform: 'capitalize' }}>
                    Manager Comments
                  </Typography>
                  <Typography variant="body2" gutterBottom component="span">
                    <div
                      dangerouslySetInnerHTML={{ __html: assessment?.managerAssessment?.completedTasks.replaceAll('\n', '<br />') }}
                    />
                  </Typography>
                </React.Fragment>
                )}
              </CardContent>
            </CollapsibleCard>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleCard
              title="Incomplete Tasks"
            >
              <CardContent>
                <React.Fragment key="paragraph">
                  <Typography noWrap variant="caption" color="primary" style={{ textTransform: 'capitalize' }}>
                    Staff Comments
                  </Typography>
                  <Typography variant="body2" gutterBottom component="span">
                    <div
                      dangerouslySetInnerHTML={{ __html: assessment?.incompleteTasks.replaceAll('\n', '<br />') }}
                    />
                  </Typography>
                </React.Fragment>
                {assessment?.managerAssessment?.incompleteTasks && (
                  <React.Fragment key="paragraph">
                    <Typography noWrap variant="caption" color="primary" style={{ textTransform: 'capitalize' }}>
                      Manager Comments
                    </Typography>
                    <Typography variant="body2" gutterBottom component="span">
                      <div
                        dangerouslySetInnerHTML={{ __html: assessment?.managerAssessment?.incompleteTasks.replaceAll('\n', '<br />') }}
                      />
                    </Typography>
                  </React.Fragment>
                )}
              </CardContent>
            </CollapsibleCard>
          </Grid>
          <Grid item xs={12}>
            <CollapsibleCard
              title="Improvement Opportunities"
            >
              <CardContent>
                <React.Fragment key="paragraph">
                  <Typography noWrap variant="caption" color="primary" style={{ textTransform: 'capitalize' }}>
                    Staff Comments
                  </Typography>
                  <Typography variant="body2" gutterBottom component="span">
                    <div
                      dangerouslySetInnerHTML={{ __html: assessment?.improvementOpportunities.replaceAll('\n', '<br />') }}
                    />
                  </Typography>
                </React.Fragment>
                {assessment?.managerAssessment?.improvementOpportunities && (
                  <React.Fragment key="paragraph">
                    <Typography noWrap variant="caption" color="primary" style={{ textTransform: 'capitalize' }}>
                      Manager Comments
                    </Typography>
                    <Typography variant="body2" gutterBottom component="span">
                      <div
                        dangerouslySetInnerHTML={{ __html: assessment?.managerAssessment?.improvementOpportunities.replaceAll('\n', '<br />') }}
                      />
                    </Typography>
                  </React.Fragment>
                )}
              </CardContent>
            </CollapsibleCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function AssessmentDetails() {
  const { assessmentId } = useParams();
  const {
    getStaffByUid,
    fetchStaffByUidData,
    coreClient,
    createManagerAssessmentData,
    updateAssessmentData,
    createAssessmentData,
  } = useCore();

  const {
    data: fetchAssessmentData,
    loading: fetchingAssessment,
    refetch: fetchAssessmentByAssessmentId,
  } = useQuery(GET_ASSESSMENT_BY_ASSESSMENT_ID, {
    client: coreClient,
    variables: { assessmentId },
  });

  const { currentUser } = useAuth();

  const { setChangeHistoryReferenceId } = useChangeHistory();
  useEffect(() => {
    if (assessmentId) {
      setChangeHistoryReferenceId(assessmentId);
    }
  }, [assessmentId]);

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      getStaffByUid({ uid: currentUser.uid });
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (assessmentId) {
      fetchAssessmentByAssessmentId({ assessmentId });
    }
  }, [assessmentId, createManagerAssessmentData, updateAssessmentData, createAssessmentData]);

  if (fetchingAssessment) return <Loading />;
  if (!fetchAssessmentData?.assessment) return <div />;
  return (
    <>
      <Wrapped assessment={fetchAssessmentData?.assessment} />
      <CreateAssessmentDialog
        referenceId={fetchAssessmentData?.assessment.assessmentId}
        approverId={fetchStaffByUidData?.staffByUid.manager.staffId}
      />
      <UpdateAssessmentDialog
        referenceId={fetchAssessmentData?.assessment.assessmentId}
      />
    </>
  );
}
