/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl, TextField, Typography,
} from '@mui/material';
import {
  CoreDialogProvider, JobType, useCore, useCoreDialog,
} from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { closeUpdateJobDialog, MilimoSlice } from '../../reducers';

const employmentTypes = ['Full-Time', 'Part-Time'];

function Wrapped({ job }: { job: JobType }) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [employmentType, setEmploymentType] = useState<string>();
  const [salary, setSalary] = useState<number>();
  const [level, setLevel] = useState<number>();

  const {
    updateJob,
    updatingJob,
    errorUpdatingJob,
    updateJobData,
  } = useCore();

  const dispatch = useDispatch();
  const updateJobDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.updateJobDialogOpen,
  );

  const {
    dialogOpen,
    setDialogOpen,
  } = useCoreDialog();

  useEffect(() => {
    setDialogOpen(updateJobDialogOpen);
  }, [updateJobDialogOpen]);

  const handleSubmitJob = () => {
    if (job?.jobId) {
      updateJob({
        jobId: job?.jobId,
        title,
        description,
        employmentType,
        salary,
        level,
      });
    }
  };

  useEffect(() => {
    if (errorUpdatingJob) {
      setErrorMessage('error adding job');
    }
  }, [errorUpdatingJob]);

  useEffect(() => {
    if (updateJobData) {
      dispatch(closeUpdateJobDialog());
    }
  }, [updateJobData]);

  if (updatingJob) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Update Job"
      submitAction={() => handleSubmitJob()}
      onClose={() => dispatch(closeUpdateJobDialog())}
      dialogOpen={dialogOpen}
    >
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <FormControl>
        <Container component="main" maxWidth="xs">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="job-title"
            label="Job Title"
            name="Job Title"
            autoFocus
            onChange={(e) => setTitle(e.target.value)}
            placeholder={job.title}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="job-description"
            label="Job Description"
            name="Job Description"
            autoFocus
            onChange={(e) => setDescription(e.target.value)}
            placeholder={job.description}
          />
          <Autocomplete
            fullWidth
            onChange={(event, value) => setEmploymentType(value || undefined)}
            id="select-employment-type-autocomplete"
            options={employmentTypes}
            getOptionLabel={(option) => (`${option}`)}
            noOptionsText="No employment type options: contact admin"
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Employment Type"
                variant="outlined"
                placeholder={job.employmentType}
              />
            )}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="salary"
            label="Salary"
            name="Salary"
            type="number"
            autoFocus
            onChange={(e) => setSalary(parseFloat(e.target.value))}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="level"
            label="Level"
            name="Level"
            type="number"
            autoFocus
            onChange={(e) => setLevel(parseFloat(e.target.value))}
          />
        </Container>
      </FormControl>
    </CoreDialog>
  );
}

export function UpdateJobDialog({ job }: { job: JobType }) {
  return (
    <CoreDialogProvider>
      <Wrapped job={job} />
    </CoreDialogProvider>
  );
}
