/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl, TextField, Typography,
} from '@mui/material';
import { useCore, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useDispatch, useSelector } from 'react-redux';
import { closeCreateOrganizationDialog, MilimoSlice } from '../../reducers';

export function AddOrganizationDialog() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [organizationName, setOrganizationName] = useState<string>();
  const {
    createOrganization,
    createOrganizationData,
    creatingOrganization,
    errorCreatingOrganization,
  } = useCore();

  const dispatch = useDispatch();
  const createOrganizationDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.createOrganizationDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (organizationName) {
      setSubmitDisabled(false);
    }
    // eslint-disable-next-line
  }, [organizationName]);

  useEffect(() => {
    setDialogOpen(createOrganizationDialogOpen);
    // eslint-disable-next-line
  }, [createOrganizationDialogOpen]);

  const handleSubmitOrganization = () => {
    if (organizationName){
      createOrganization({ organizationName }).then(() => {
        dispatch(closeCreateOrganizationDialog());
      });
    }
  };

  useEffect(() => {
    if (errorCreatingOrganization) {
      setErrorMessage('error creating organization');
    }
  }, [errorCreatingOrganization]);

  useEffect(() => {
    if (createOrganizationData) {
      dispatch(closeCreateOrganizationDialog());
    }
    // eslint-disable-next-line
  }, [createOrganizationData]);

  if (creatingOrganization) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Create Organization"
      submitAction={() => handleSubmitOrganization()}
      onClose={() => dispatch(closeCreateOrganizationDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <FormControl>
        <Container component="main" maxWidth="xs">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="organization-name"
            label="Organization Name"
            name="Organization Name"
            autoFocus
            onChange={(e) => setOrganizationName(e.target.value)}
          />
        </Container>
      </FormControl>
    </CoreDialog>
  );
}
