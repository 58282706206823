/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MilimoSlice {
  addDepartmentDialogOpen: boolean,
  addJobDialogOpen: boolean,
  addPositionDialogOpen: boolean,
  createAssessmentDialogOpen: boolean,
  createBillingDialogOpen: boolean,
  createOrganizationDialogOpen: boolean,
  editItemDialogOpen: boolean,
  updateDepartmentDialogOpen: boolean,
  updateAssessmentDialogOpen: boolean,
  updateJobDialogOpen: boolean,
  updateJobPositionDialogOpen: boolean,
  updatePositionReferenceId: string,
}

export const milimoSlice = createSlice({
  name: 'milimo',
  initialState: {
    addDepartmentDialogOpen: false,
    addJobDialogOpen: false,
    addPositionDialogOpen: false,
    createAssessmentDialogOpen: false,
    createBillingDialogOpen: false,
    createOrganizationDialogOpen: false,
    editItemDialogOpen: false,
    updateDepartmentDialogOpen: false,
    updateAssessmentDialogOpen: false,
    updateJobDialogOpen: false,
    updateJobPositionDialogOpen: false,
    updatePositionReferenceId: '',
  },
  reducers: {
    closeAddDepartmentDialog: (state) => { state.addDepartmentDialogOpen = false; },
    closeAddJobDialog: (state) => { state.addJobDialogOpen = false; },
    closeAddPositionDialog: (state) => { state.addPositionDialogOpen = false; },
    closeCreateAssessmentDialog: (state) => { state.createAssessmentDialogOpen = false; },
    closeCreateBillingDialog: (state) => { state.createBillingDialogOpen = false; },
    closeCreateOrganizationDialog: (state) => { state.createOrganizationDialogOpen = false; },
    closeEditItemDialog: (state) => { state.editItemDialogOpen = false; },
    closeUpdateDepartmentDialog: (state) => { state.updateDepartmentDialogOpen = false; },
    closeUpdateAssessmentDialog: (state) => { state.updateAssessmentDialogOpen = false; },
    closeUpdateJobDialog: (state) => { state.updateJobDialogOpen = false; },
    closeUpdateJobPositionDialog: (state) => { state.updateJobPositionDialogOpen = false; },
    openAddDepartmentDialog: (state) => { state.addDepartmentDialogOpen = true; },
    openAddJobDialog: (state) => { state.addJobDialogOpen = true; },
    openAddPositionDialog: (state) => { state.addPositionDialogOpen = true; },
    openCreateAssessmentDialog: (state) => { state.createAssessmentDialogOpen = true; },
    openCreateBillingDialog: (state) => { state.createBillingDialogOpen = true; },
    openCreateOrganizationDialog: (state) => { state.createOrganizationDialogOpen = true; },
    openEditItemDialog: (state) => { state.editItemDialogOpen = true; },
    openUpdateDepartmentDialog: (state) => { state.updateDepartmentDialogOpen = true; },
    openUpdateAssessmentDialog: (state) => { state.updateAssessmentDialogOpen = true; },
    openUpdateJobDialog: (state) => { state.updateJobDialogOpen = true; },
    openUpdateJobPositionDialog: (state) => { state.updateJobPositionDialogOpen = true; },
    setUpdatePositionReferenceId: (state, action: PayloadAction<string>) => { state.updatePositionReferenceId = action.payload; },
  },
});

export const {
  closeAddDepartmentDialog,
  closeAddJobDialog,
  closeAddPositionDialog,
  closeCreateAssessmentDialog,
  closeCreateBillingDialog,
  closeCreateOrganizationDialog,
  closeEditItemDialog,
  closeUpdateAssessmentDialog,
  closeUpdateDepartmentDialog,
  closeUpdateJobDialog,
  closeUpdateJobPositionDialog,
  openAddDepartmentDialog,
  openAddJobDialog,
  openAddPositionDialog,
  openCreateAssessmentDialog,
  openCreateBillingDialog,
  openCreateOrganizationDialog,
  openEditItemDialog,
  openUpdateAssessmentDialog,
  openUpdateDepartmentDialog,
  openUpdateJobDialog,
  openUpdateJobPositionDialog,
  setUpdatePositionReferenceId,
} = milimoSlice.actions;

export default milimoSlice.reducer;
