import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Think-Zambia-Medium',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#00363a',
      main: '#00363a',
      dark: '#00363a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#006064',
      main: '#006064',
      dark: '#428e92',
      contrastText: '#fff',
    },
    text: {
      secondary: '#00363a',
    },
  },
});

export default theme;
