/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid, CardContent, Toolbar, Fab, Tooltip, Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  InvoiceItemType,
  InvoiceType,
  openAddInvoiceItemDialog,
  useAuth,
  useCore,
  GET_INVOICE_BY_INVOICE_ID,
  openUpdateInvoiceDialog,
} from '@think-zambia-foundation/context';
import {
  SmartTypography, TableList, Loading, CollapsibleCard, UpdateInvoiceDialog,
} from '@think-zambia-foundation/components';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { openEditItemDialog } from '../../reducers';
import { AddItemDialog } from '../../components/AddItemDialog/AddItemDialog';
import { EditItemDialog } from '../../components';

function InvoiceItems(
  { items }: { items: InvoiceItemType[] },
) {
  const [disableAddItem, setDisableAddItem] = useState(false);
  const [disableEditItem, setDisableEditItem] = useState(false);
  const [disableRemoveItem, setDisableRemoveItem] = useState(false);

  const [editItemReferenceId, setEditItemReferenceId] = useState<string>();

  const {
    removeItem,
  } = useCore();

  const {
    currentUser,
  } = useAuth();

  const dispatch = useDispatch();

  function editItem(itemId: string) {
    setEditItemReferenceId(itemId);
    dispatch(openEditItemDialog());
  }

  useEffect(() => {
    if (currentUser === null) {
      setDisableAddItem(true);
      setDisableEditItem(true);
      setDisableRemoveItem(true);
    }
  });

  return (
    <>
      <CollapsibleCard
        title="Items"
        actions={[
          {
            key: '3104f9f095114b9483303271db7c0d1e',
            callback: () => dispatch(openAddInvoiceItemDialog()),
            icon: <AddTwoToneIcon />,
            disableCard: disableAddItem,
          },
        ]}
      >
        <TableList
          data={items}
          tableMetadata={{
            href: '/item',
            tableKey: 'itemId',
            secondaryActions: [{
              key: '125f5d83fca14244a7d4860d138fbd01',
              callback: (itemId) => (disableRemoveItem ? {} : removeItem({ itemId })),
              icon: () => <DeleteIcon />,
              text: 'Delete',
              disableCard: () => disableRemoveItem,
            }],
            onClickCell: (itemId) => {
              if (!disableEditItem) {
                editItem(itemId);
              }
            },
            cells: [
              {
                key: 'name',
                header: 'Name',
                variant: 'name',
              }, {
                key: 'description',
                header: 'Description',
                variant: 'default',
              }, {
                key: 'unitPrice',
                header: 'Unit Price',
                variant: 'default',
              }, {
                key: 'unit',
                header: 'Unit',
                variant: 'default',
              }, {
                key: 'quantity',
                header: 'Quantity',
                variant: 'default',
              }],
          }}
          disableSearch
          elevation={0}
        />
      </CollapsibleCard>
      {editItemReferenceId && (
        <EditItemDialog referenceId={editItemReferenceId} />
      )}
    </>
  );
}

export function Invoice(
  { invoice }: { invoice: InvoiceType },
) {
  const [totalDue, setTotalDue] = useState<number>();
  const [disableUpdateInvoice, setDisableUpdateInvoice] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  useEffect(() => {
    setTotalDue(invoice?.items?.reduce((prev, current) => prev + current.unitPrice, 0) || 0);
  }, [invoice]);

  useEffect(() => {
    if (currentUser === null) {
      setDisableUpdateInvoice(true);
    }
  }, [currentUser]);
  return (
    <>
      <CollapsibleCard
        title="Invoice Details"
        actions={[
          {
            key: '4504f9f095114b9483303271db7c0d1e',
            callback: () => dispatch(openUpdateInvoiceDialog()),
            icon: <EditTwoToneIcon />,
            disableCard: disableUpdateInvoice,
          },
        ]}
      >
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12}>
              <SmartTypography
                text={invoice.invoiceId}
                variant="name"
                caption="Invoice Number"
              />
            </Grid>
            {invoice?.department?.organization && (
            <Grid item xs={12} sm={6}>
              <SmartTypography
                text={invoice?.department?.organization?.name}
                variant="name"
                caption="Company Information"
              />
              <SmartTypography
                text={invoice?.department?.organization?.address?.addressLine1}
                variant="name"
              />
              <SmartTypography
                text={
                  `${invoice?.department?.organization?.address?.city || ''}
                ${invoice?.department?.organization?.address?.province || ''}
                ${invoice?.department?.organization?.address?.country || ''}`
                }
                variant="name"
              />
              <SmartTypography
                text={invoice?.department?.organization?.phone1}
                variant="phone"
              />
              <SmartTypography
                text={invoice?.department?.organization?.phone2}
                variant="phone"
              />
              <SmartTypography
                text={invoice?.department?.organization?.email}
                variant="email"
              />
            </Grid>
            )}
            {invoice.client
            && (
              <Grid item xs={12} sm={6}>
                <SmartTypography
                  text={invoice.client.name}
                  variant="name"
                  caption="Client Information"
                />
                <SmartTypography
                  text={invoice?.client?.address?.addressLine1 || ''}
                  variant="name"
                />
                <SmartTypography
                  caption="Address"
                  variant="name"
                  text={`${invoice?.client?.address?.addressLine1 || ''}`}
                />
                {invoice?.client?.address?.addressLine2
                  && <Typography variant="body2">{invoice?.client?.address?.addressLine2}</Typography>}
                {invoice?.client?.address?.city
                  && <Typography variant="body2">{invoice?.client?.address?.city}</Typography>}
                {invoice?.client?.address?.province
                  && <Typography variant="body2">{invoice?.client?.address?.province}</Typography>}
                {invoice?.client?.address?.postalCode
                  && <Typography variant="body2">{invoice?.client?.address?.postalCode}</Typography>}
                {invoice?.client?.address?.country
                  && <Typography variant="body2">{invoice?.client?.address?.country}</Typography>}
                <SmartTypography
                  text={invoice.client.phone1}
                  variant="phone"
                />
                <SmartTypography
                  text={invoice.client.phone2}
                  variant="phone"
                />
                <SmartTypography
                  text={invoice.client.email}
                  variant="email"
                />
              </Grid>
            )}
            {invoice.billing && (
            <Grid item xs={12} sm={12}>
              <SmartTypography
                text={invoice.billing.name}
                variant="name"
                caption="Billing Information"
              />
              <SmartTypography
                text={invoice.billing.account}
                variant="name"
              />
              <SmartTypography
                text={invoice.billing.email}
                variant="email"
              />
            </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <SmartTypography
                text={invoice.createdDate}
                variant="name"
                caption="Created Date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SmartTypography
                text={invoice.invoiceDueDate}
                variant="name"
                caption="Due Date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SmartTypography
                text={invoice.status}
                variant="name"
                caption="Status"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SmartTypography
                text={`${totalDue}`}
                variant="name"
                caption="Total Due"
              />
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>
      <UpdateInvoiceDialog invoiceId={invoice?.invoiceId} />
    </>
  );
}

export function RenderInvoiceDetails(
  { invoice }: { invoice: InvoiceType },
) {
  const {
    approveInvoice,
    approvingInvoice,
    rejectingInvoice,
    rejectInvoice,
    submitInvoice,
    cancelInvoice,
    cancellingInvoice,
    submittingInvoice,
  } = useCore();

  const {
    currentUser,
  } = useAuth();

  const [disableApproveInvoice, setDisableApproveInvoice] = useState(true);
  const [disableSubmitInvoice, setDisableSubmitInvoice] = useState(true);
  const [disableCancelInvoice, setDisableCancelInvoice] = useState(true);
  const [disableFabs, setDisableFabs] = useState(false);

  useEffect(() => {
    if (currentUser === null) {
      setDisableFabs(true);
    }
  });

  useEffect(() => {
    if (approvingInvoice || rejectingInvoice || cancellingInvoice || submittingInvoice) {
      setDisableApproveInvoice(true);
      setDisableSubmitInvoice(true);
      setDisableCancelInvoice(true);
    } else {
      if (invoice && (invoice.status === 'CREATED' || invoice.status === 'SUBMITTED')) {
        setDisableCancelInvoice(false);
      } else {
        setDisableCancelInvoice(true);
      }

      if (currentUser
        && invoice
        && invoice.department
        && invoice.department.headOfDepartment
        && currentUser.uid === invoice.department.headOfDepartment.uid
        && invoice.status === 'SUBMITTED'
      ) {
        setDisableApproveInvoice(false);
      } else {
        setDisableApproveInvoice(true);
      }

      if (invoice && invoice.status === 'CREATED') {
        setDisableSubmitInvoice(false);
      } else {
        setDisableSubmitInvoice(true);
      }
    }
  }, [
    currentUser,
    invoice,
    rejectingInvoice,
    approvingInvoice,
    cancellingInvoice,
    submittingInvoice,
  ]);

  if (!invoice) return <div />;
  return (
    <Grid
      container
      spacing={1}
    >
      <Grid item xs={12}>
        <Invoice invoice={invoice} />
      </Grid>
      <Grid item xs={12}>
        <Toolbar />
      </Grid>
      <Grid item xs={12}>
        <InvoiceItems items={invoice.items} />
        <AddItemDialog invoiceId={invoice.invoiceId} />
      </Grid>
      <Grid item xs={12}>
        <Toolbar />
      </Grid>
      {!disableFabs && (
      <div style={{
        position: 'fixed',
        bottom: '6.25em',
        right: '6.25em',
      }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              disabled={disableSubmitInvoice}
              color="secondary"
              onClick={() => { submitInvoice({ invoiceId: invoice.invoiceId }); }}
            >
              <Tooltip title="submit invoice" arrow>
                <SendIcon />
              </Tooltip>
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              disabled={disableApproveInvoice}
              color="secondary"
              onClick={() => { approveInvoice({ invoiceId: invoice.invoiceId }); }}
            >
              <Tooltip title="approve invoice" arrow>
                <DoneIcon />
              </Tooltip>
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              disabled={disableApproveInvoice}
              color="secondary"
              onClick={() => { rejectInvoice({ invoiceId: invoice.invoiceId }); }}
            >
              <Tooltip title="reject invoice" arrow>
                <CloseIcon />
              </Tooltip>
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              disabled={disableCancelInvoice}
              color="secondary"
              onClick={() => { cancelInvoice({ invoiceId: invoice.invoiceId }); }}
            >
              <Tooltip title="cancel invoice" arrow>
                <DoNotDisturbIcon />
              </Tooltip>
            </Fab>
          </Grid>
        </Grid>
      </div>
      )}
    </Grid>
  );
}

export function InvoiceDetails() {
  const [invoice, setInvoice] = useState();
  const { invoiceId } = useParams();

  const {
    addItemData,
    coreClient,
    approveInvoiceData,
    rejectInvoiceData,
    cancelInvoiceData,
    submitInvoiceData,
    removeItemData,
    editItemData,
    updateInvoiceData,
  } = useCore();

  const {
    error: errorFetchingInvoiceByInvoiceId,
    loading: fetchingInvoiceByInvoiceId,
    data: fetchInvoiceByInvoiceIdData,
    refetch: getInvoiceByInvoiceId,
  } = useQuery(GET_INVOICE_BY_INVOICE_ID, {
    client: coreClient,
    variables: { invoiceId },
  });

  useEffect(() => {
    if (invoiceId) {
      getInvoiceByInvoiceId({ invoiceId });
    }
  }, [invoiceId,
    addItemData,
    approveInvoiceData,
    rejectInvoiceData,
    cancelInvoiceData,
    submitInvoiceData,
    removeItemData,
    editItemData,
    updateInvoiceData,
  ]);

  useEffect(() => {
    setInvoice(undefined);
  }, [errorFetchingInvoiceByInvoiceId]);

  useEffect(() => {
    if (fetchInvoiceByInvoiceIdData) {
      setInvoice(fetchInvoiceByInvoiceIdData.invoiceByInvoiceId);
    }
  }, [fetchInvoiceByInvoiceIdData]);

  if (fetchingInvoiceByInvoiceId) return <Loading />;
  if (!invoice) return <div />;
  return (
    <RenderInvoiceDetails invoice={invoice} />
  );
}
