/* eslint-disable react/prop-types */
import {
  CardContent,
  Grid,
} from '@mui/material';
import { CollapsibleCard, SmartTypography, TableList } from '@think-zambia-foundation/components';
import React from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useLocation } from 'react-router-dom';
import { JobType, PositionType, useCore } from '@think-zambia-foundation/context';
import { useDispatch } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
  openAddPositionDialog,
  openUpdateJobDialog,
  openUpdateJobPositionDialog,
  setUpdatePositionReferenceId,
} from '../../reducers';
import { AddPositionDialog, UpdateJobDialog, UpdatePositionDialog } from '../../components';

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

export interface JobDetailsProps {
  job: JobType
}

function JobDetails(
  { job }: JobDetailsProps,
) {
  const dispatch = useDispatch();
  const query = useQueryParam();
  const jobId = query.get('jobId');

  const handleUpdateDepartment = () => {
    if (jobId) {
      dispatch(openUpdateJobDialog());
    }
  };
  if (!job) return <div />;
  return (
    <>
      <CollapsibleCard
        title="Job Information"
        actions={[
          {
            key: '9492e10d5ea54efe8ad161b8446f93fd',
            callback: () => handleUpdateDepartment(),
            icon: <EditTwoToneIcon />,
          },
        ]}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SmartTypography caption="Title" text={job.title} />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography caption="Description" text={job.description || '-'} variant="paragraph" />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography caption="Employment Type" text={job.employmentType || '-'} />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography caption="Level" text={`${job.level || '-'}`} />
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>
      {jobId && (
        <UpdateJobDialog job={job} />
      )}
    </>
  );
}

export interface PositionsProps {
  positions: PositionType[]
}

function Positions(
  { positions }: PositionsProps,
) {
  const query = useQueryParam();
  const jobId = query.get('jobId');

  const dispatch = useDispatch();
  const handleAddPosition = () => {
    if (jobId) {
      dispatch(openAddPositionDialog());
    }
  };

  const {
    resetPosition,
  } = useCore();

  return (
    <>
      <CollapsibleCard
        title="Positions"
        actions={[
          {
            key: 'b18e599521bd415cabbaf9f176dfb59b',
            callback: () => handleAddPosition(),
            icon: <AddTwoToneIcon />,
          },
        ]}
      >
        {positions && (
          <>
            <TableList
              data={positions || []}
              disableSearch
              hideCSVDownload
              hidePagination
              hideHeader
              elevation={0}
              tableMetadata={{
                tableKey: 'positionId',
                onClickCell: () => {},
                secondaryActions: [{
                  key: 'd2d868b664904d5380b4faa71910e9fe',
                  callback: () => {},
                  icon: () => <ClearIcon color="primary" fontSize="small" />,
                  text: 'Archive Position',
                }, {
                  key: '3733f1ed15104315a68641f9a3ce9fa3',
                  callback: (position) => {
                    resetPosition({ positionId: position.positionId });
                  },
                  icon: () => <PersonOffIcon color="primary" />,
                  text: 'Remove Employee',
                  disableCard: (position) => !position.staff,
                }, {
                  key: 'f929d8def6d34265b60f3aa837235f9d',
                  callback: (position) => {
                    dispatch(setUpdatePositionReferenceId(position.positionId));
                    dispatch(openUpdateJobPositionDialog());
                  },
                  icon: () => <EditTwoToneIcon color="primary" />,
                  text: 'Edit Employee',
                }],
                cells: [{
                  key: 'name',
                  header: 'Name',
                  variant: 'name',
                  value(position) { return position.staff ? `${position.staff.firstName} ${position.staff.lastName}` : 'Vacant'; },
                }],
              }}
            />
            <UpdatePositionDialog />
          </>
        )}
      </CollapsibleCard>
      {jobId && (
        <AddPositionDialog jobId={jobId} />
      )}
    </>
  );
}

export interface JobInformationCardProps {
  job: JobType
}

export function JobInformationCard(
  { job }: JobInformationCardProps,
) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <JobDetails job={job} />
      </Grid>
      <Grid item xs={12}>
        <Positions positions={job.positions} />
      </Grid>
    </Grid>
  );
}
