import AddTwoTone from '@mui/icons-material/AddTwoTone';
import { CollapsibleCard, TableList } from '@think-zambia-foundation/components';
import { JobType } from '@think-zambia-foundation/context';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { AddJobDialog } from '../../components';
import { openAddJobDialog } from '../../reducers';

export interface JobListProps {
  jobs: JobType[]
  organizationId: string
  referenceId: string
}

export function JobList(
  {
    jobs,
    organizationId,
    referenceId,
  }: JobListProps,
) {
  const navigate = useNavigate();

  function handleSelectJob(
    jobId: string,
  ) {
    if (!jobId) return;
    navigate(`/organization/${organizationId}/?departmentId=${referenceId}&jobId=${jobId}`);
  }

  const dispatch = useDispatch();

  const handleAddJob = () => {
    if (organizationId) {
      dispatch(openAddJobDialog());
    }
  };

  return (
    <>
      <CollapsibleCard
        title="Jobs"
        actions={[
          {
            key: '97be4319d6ca4987a450a2bf2e52b6e0',
            callback: () => handleAddJob(),
            icon: <AddTwoTone />,
          },
        ]}
      >
        {jobs && (
        <TableList
          data={jobs || []}
          disableSearch
          hideCSVDownload
          hidePagination
          hideHeader
          elevation={0}
          tableMetadata={{
            tableKey: 'jobId',
            onClickCell: (jobId) => {
              handleSelectJob(jobId);
            },
            secondaryActions: [{
              key: '4c2605579f9546388417a5ff6fef29b1',
              callback: () => {},
              icon: () => <ClearIcon color="primary" fontSize="small" />,
            }],
            cells: [{
              key: 'title',
              header: 'Title',
              variant: 'name',
            }],
          }}
        />
        )}
      </CollapsibleCard>
      {referenceId && (
      <AddJobDialog referenceId={referenceId} />
      )}
    </>
  );
}
