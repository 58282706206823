/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { CardContent, Grid, Typography } from '@mui/material';

export function Dashboard() {
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      setTitle('Good morning');
    } else if (curHr < 18) {
      setTitle('Good afternoon');
    } else {
      setTitle('Good evening');
    }
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Grid item sm={12} xs={12}>
        <CardContent>
          <Typography variant="h4">{title || 'Hello'}</Typography>
        </CardContent>
      </Grid>
    </Grid>
  );
}
