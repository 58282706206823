import {
  StaffList,
  JobApplicationList,
  JobApplicationDetails,
} from '@think-zambia-foundation/components';
import {
  AssessmentDetails,
  Assessments,
  InvoiceDetails,
  InvoiceListController,
  MilimoStaffDetails,
  OrganizationDetails,
  Organizations,
  Dashboard,
} from './pages';

export const routes = [
  {
    exact: true,
    path: '/',
    component: Dashboard,
    title: 'Dashboard',
  },
  {
    exact: true,
    path: '/staff',
    component: StaffList,
    title: 'Staff',
  },
  {
    exact: false,
    path: '/staff/:staffId',
    component: MilimoStaffDetails,
    title: 'Staff Details',
  },
  {
    exact: true,
    path: '/jobApplication',
    component: JobApplicationList,
    title: 'Job Applications',
  },
  {
    exact: false,
    path: '/jobApplication/:jobApplicationId',
    component: JobApplicationDetails,
    title: 'Job Application Details',
  },
  {
    exact: true,
    path: '/organization',
    component: Organizations,
    title: 'Organizations',
  },
  {
    exact: false,
    path: '/organization/:organizationId',
    component: OrganizationDetails,
    title: 'Organization Details',
  },
  {
    exact: false,
    path: '/assessment/:assessmentId',
    component: AssessmentDetails,
    title: 'Organization Details',
  },
  {
    exact: true,
    path: '/invoice',
    component: InvoiceListController,
    title: 'Invoices',
  },
  {
    path: '/assessment',
    exact: true,
    component: Assessments,
    title: 'Assessments',
  },
  {
    path: '/invoice/:invoiceId',
    component: InvoiceDetails,
    title: 'Invoice Details',
    exact: false,
  },
];

export const publicRoutes = [
];
