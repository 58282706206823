import React from 'react';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
// import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
// import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const menu = [
  {
    id: 'e43253102ae24388bdbdeaf632e2a3ab',
    text: 'Dashboard',
    secondaryText: 'Dashboard',
    link: '/',
    icon: <HomeIcon style={{ fontSize: '1.5em' }} color="secondary" />,
    divider: true,
  },
  {
    id: 'a8e9e540c14f4e0dbc86d1cb55405f71',
    text: 'Staff',
    secondaryText: 'List of staff',
    link: '/staff',
    icon: <PeopleOutlineTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  /*
  {
    id: '4d4d4f8ec6e647aca7e4cbd36f3903da',
    claim: 'jobApplicationUser',
    text: 'Job Applications',
    secondaryText: 'List of job applications',
    link: '/jobApplication',
    icon: <WorkTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  {
    id: 'd7163a7d699a411bb50c4604ae01be68',
    claim: 'billUser',
    text: 'Bills',
    secondaryText: 'List of bills',
    link: '/bill',
    icon: <AccountBalanceWallet style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  */
  {
    id: '5e3dfa65232e80e3f9189c39f9928dd4',
    claim: 'invoiceUser',
    text: 'Invoices',
    secondaryText: 'List of invoices',
    link: '/invoice',
    icon: <ReceiptIcon style={{ fontSize: '1.5em' }} color="secondary" />,
    divider: true,
  },
  {
    id: 'a44a2ee81b4948eca0a4b79a014e5a13',
    text: 'Assessments',
    secondaryText: 'View Assessments',
    link: '/assessment',
    icon: <EmojiEventsIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
];

export default menu;
