/* eslint-disable react/require-default-props */
import AddTwoTone from '@mui/icons-material/AddTwoTone';
import { CollapsibleCard, TableList } from '@think-zambia-foundation/components';
import { BillingType } from '@think-zambia-foundation/context';
import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { openCreateBillingDialog } from '../../reducers';

export interface BillingListProps {
  billingAccounts: BillingType[]
  organizationId?: string
}

export function BillingList({
  billingAccounts,
  organizationId,
}: BillingListProps) {
  const dispatch = useDispatch();

  const handleAddBilling = () => {
    dispatch(openCreateBillingDialog());
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickBilling = (billingId: string) => {
  };

  return (
    <CollapsibleCard
      title="Accounts"
      actions={[
        {
          key: '5a5dc0b565a64bd1b6ddd0a88efe8e20',
          callback: () => handleAddBilling(),
          icon: <AddTwoTone />,
          disableCard: !organizationId,
        },
      ]}
    >
      {billingAccounts && (
        <TableList
          data={billingAccounts || []}
          disableSearch
          hideCSVDownload
          hidePagination
          hideHeader
            // selected={selectedCell}
            // loading={loading}
          elevation={0}
          tableMetadata={{
            tableKey: 'billingId',
            onClickCell: (billingId) => {
              handleClickBilling(billingId);
            },
            secondaryActions: [{
              key: '5eab1333a84c466f9f89d98ddb1751d2',
              callback: () => {},
              icon: () => <ClearIcon color="primary" fontSize="small" />,
            }],
            cells: [{
              key: 'name',
              header: 'Name',
              variant: 'name',
              value(billing) { return `${billing.name} (${billing.account.slice(0, billing.account.length / 3)}********)`; },
            }],
          }}
        />
      )}
    </CollapsibleCard>
  );
}
