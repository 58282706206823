/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Grid, TextField as MaterialTextField, Typography,
} from '@mui/material';
import {
  CoreDialogProvider, useCore, useCoreDialog,
} from '@think-zambia-foundation/context';
import { Loading, CoreDialog } from '@think-zambia-foundation/components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeCreateBillingDialog, MilimoSlice } from '../../reducers';

function Wrapped() {
  const [account, setAccount] = useState<string>();
  const [swift, setSwift] = useState<string>();
  const [phone1, setPhone1] = useState<string>();
  const [addressLine1, setAddressLine1] = useState<string>();
  const [addressLine2, setAddressLine2] = useState<string>();
  const [city, setCity] = useState<string>();
  const [province, setProvince] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();

  const { organizationId } = useParams();

  const {
    creatingBilling,
    errorCreatingBilling,
    createBillingData,
    createBilling,
  } = useCore();

  const dispatch = useDispatch();
  const createBillingDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.createBillingDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (organizationId && name && account) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [organizationId, name, account]);

  useEffect(() => {
    setDialogOpen(createBillingDialogOpen);
  }, [createBillingDialogOpen]);

  useEffect(() => {
    if (createBillingData && createBillingData.createBilling) {
      dispatch(closeCreateBillingDialog());
    }
  }, [createBillingData]);

  const handleSubmitCreateBilling = () => {
    if (organizationId
      && account
      && name){
      createBilling({
        referenceId: organizationId,
        account,
        swift: swift || '',
        name,
        email: email || '',
        phone1: phone1 || '',
        phone2: '',
        addressLine1: addressLine1 || '',
        addressLine2: addressLine2 || '',
        city: city || '',
        province: province || '',
        country: country || '',
      });
    }
  };

  return (
    <CoreDialog
      modalTitle="Create Account"
      submitAction={() => handleSubmitCreateBilling()}
      onClose={() => dispatch(closeCreateBillingDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >

        <Grid item xs={12}>
          { creatingBilling && (
          <Loading />
          )}
          {errorCreatingBilling && (
            <Typography variant="body2" color="error">{errorCreatingBilling.message}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            required
            id="name"
            label="Account Name"
            name="Account Name"
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            required
            id="account"
            label="Account Number"
            name="Account Number"
            onChange={(e) => setAccount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="email"
            label="Billing Email"
            name="Billing Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="swift"
            label="Swift"
            name="Swift"
            onChange={(e) => setSwift(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="phone1"
            label="Phone"
            name="Phone"
            onChange={(e) => setPhone1(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="addressLine1"
            label="Address Line 1"
            name="Address Line 1"
            onChange={(e) => setAddressLine1(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="addressLine2"
            label="Address Line 2"
            name="Address Line 2"
            onChange={(e) => setAddressLine2(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="city"
            label="City"
            name="City"
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="province"
            label="Province"
            name="Province"
            onChange={(e) => setProvince(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialTextField
            variant="outlined"
            fullWidth
            id="country"
            label="Country"
            name="Country"
            onChange={(e) => setCountry(e.target.value)}
          />
        </Grid>
      </Grid>
    </CoreDialog>
  );
}

export function CreateBillingDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
