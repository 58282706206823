/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  FormControl, Grid, TextField,
  Autocomplete,
} from '@mui/material';

import {
  useCoreDialog, CoreDialogProvider, useCore,
} from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useDispatch, useSelector } from 'react-redux';
import { closeEditItemDialog, MilimoSlice } from '../../reducers';

export interface EditItemDialogProps {
  referenceId: string
}

function Wrapped(
  { referenceId }: EditItemDialogProps,
) {
  const {
    dialogOpen,
    setDialogOpen,
    setSubmitDisabled,
    submitDisabled,
  } = useCoreDialog();

  const {
    editItem,
    editItemData,
  } = useCore();

  const dispatch = useDispatch();
  const editItemDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.editItemDialogOpen,
  );

  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [unit, setUnit] = useState<string>();
  const [unitPrice, setUnitPrice] = useState<number>();
  const [quantity, setQuantity] = useState<number>();

  useEffect(() => {
    if (
      name
      || unit
      || description
      || unitPrice
      || quantity
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [
    name,
    unit,
    description,
    unitPrice,
    quantity,
  ]);

  useEffect(() => {
    if (editItemData) {
      dispatch(closeEditItemDialog());
    }
  }, [editItemData]);

  useEffect(() => {
    setDialogOpen(editItemDialogOpen);
  }, [editItemDialogOpen]);

  const handleEditItem = () => {
    editItem({
      itemId: referenceId,
      invoiceId: referenceId,
      name,
      description,
      unit,
      unitPrice,
      quantity,
    });
  };

  return (
    <CoreDialog
      modalTitle="Edit Item"
      submitAction={() => handleEditItem()}
      onClose={() => dispatch(closeEditItemDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <TextField
              required
              label="Item name"
              fullWidth
              variant="outlined"
              onChange={(event) => setName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Item description"
              fullWidth
              variant="outlined"
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                setUnit(value);
              }}
              id="select-fee-autocomplete"
              options={['item', 'kg', 'm', 'l']}
              disableClearable
              fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Unit" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Unit Price"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(event) => setUnitPrice(parseFloat(event.target.value))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Quantity"
              type="number"
              onChange={(event) => setQuantity(parseFloat(event.target.value))}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function EditItemDialog(
  { referenceId }: EditItemDialogProps,
) {
  return (
    <CoreDialogProvider>
      <Wrapped
        referenceId={referenceId}
      />
    </CoreDialogProvider>
  );
}
