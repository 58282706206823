import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl, TextField, Typography,
} from '@mui/material';
import { CoreDialogProvider, useCore, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';

import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddJobDialog, MilimoSlice } from '../../reducers';

const employmentTypes = ['Full-Time', 'Part-Time'];

export interface AddJobDialogProps {
  referenceId: string
}

function Wrapped(
  { referenceId }: AddJobDialogProps,
) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [employmentType, setEmploymentType] = useState<string>();
  const [salary, setSalary] = useState<number>();
  const [level, setLevel] = useState<number>();

  const dispatch = useDispatch();
  const addJobDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.addJobDialogOpen,
  );

  const {
    addJob,
    addJobData,
    addingJob,
    errorAddingJob,
  } = useCore();

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (!referenceId
      || !title
      || !description
      || !employmentType
      || !salary
      || !level
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [referenceId, title, description, employmentType, salary, level]);

  useEffect(() => {
    setDialogOpen(addJobDialogOpen);
  }, [addJobDialogOpen]);

  const handleSubmitJob = () => {
    if (title && description && employmentType && salary && level) {
      addJob({
        referenceId,
        title,
        description,
        employmentType,
        salary,
        level,
      }).then(() => {
        dispatch(closeAddJobDialog());
      });
    }
  };

  useEffect(() => {
    if (errorAddingJob) {
      setErrorMessage('error adding job');
    }
  }, [errorAddingJob]);

  useEffect(() => {
    if (addJobData) {
      dispatch(closeAddJobDialog());
    }
  }, [addJobData]);

  return (
    <CoreDialog
      modalTitle="Add Job"
      submitAction={() => handleSubmitJob()}
      onClose={() => dispatch(closeAddJobDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={addingJob}
    >
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <FormControl>
        <Container component="main" maxWidth="xs">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="job-title"
            label="Job Title"
            name="Job Title"
            autoFocus
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="job-description"
            label="Job Description"
            name="Job Description"
            autoFocus
            onChange={(e) => setDescription(e.target.value)}
          />
          <Autocomplete
            fullWidth
            onChange={(event, value) => setEmploymentType(value || undefined)}
            id="select-employment-type-autocomplete"
            options={employmentTypes}
            getOptionLabel={(option) => (`${option}`)}
            noOptionsText="No employment type options: contact admin"
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} label="Select Employment Type" variant="outlined" />}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="salary"
            label="Salary"
            name="Salary"
            type="number"
            autoFocus
            onChange={(e) => setSalary(parseFloat(e.target.value))}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="level"
            label="Level"
            name="Level"
            type="number"
            autoFocus
            onChange={(e) => setLevel(parseFloat(e.target.value))}
          />
        </Container>
      </FormControl>
    </CoreDialog>
  );
}

export function AddJobDialog(
  { referenceId }: AddJobDialogProps,
) {
  return (
    <CoreDialogProvider>
      <Wrapped referenceId={referenceId} />
    </CoreDialogProvider>
  );
}
