import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { NkanaApp } from '@think-zambia-foundation/components';
import SettingsIcon from '@mui/icons-material/Settings';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import { milimoSlice } from './reducers';
import theme from './theme';
import menu from './menu';
import { routes, publicRoutes } from './routes';

const milimoReducer = {
  milimo: milimoSlice.reducer,
};

function MilimoWrapper({ children }: { children: React.ReactNode }){
  return (
    <div style={{ backgroundColor: '#F5F5F6', minHeight: '100vh' }}>
      {children}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <BodyBackgroundColor backgroundColor="#F5F5F6">
        <NkanaApp
          wrapper={MilimoWrapper}
          app={{
            name: 'Milimo',
            loginPath: '/login',
            forgotPasswordPath: '/forgot-password',
            menu,
            routes,
            publicRoutes,
            maxWidth: 'xl',
            settings: {
              link: '/organization',
              icon: <SettingsIcon />,
              text: 'School Settings',
            },
          }}
          reducer={milimoReducer}
        />
      </BodyBackgroundColor>
    </StyledEngineProvider>
  </ThemeProvider>,
);
