import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import {
  DepartmentType, GET_ORGANIZATION_BY_ID, JobType, useChangeHistory, useCore,
} from '@think-zambia-foundation/context';
import { useQuery } from '@apollo/client';
import { DepartmentInformationCard } from './DepartmentInformationCard';
import { OrganizationInformationCard } from './OrganizationInformationCard';
import { JobInformationCard } from './JobInformationCard';

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

function Wrapped({ organizationId }: { organizationId: string }) {
  const query = useQueryParam();
  const departmentId = query.get('departmentId');
  const jobId = query.get('jobId');

  const {
    addDepartmentData,
    addJobData,
    addPositionData,
    coreClient,
    createBillingData,
    updateDepartmentData,
    updateOrganizationData,
    updateJobData,
    updatePositionData,
    resetPositionData,
  } = useCore();

  const {
    // loading: fetchingOrganizationById,
    // error: errorFetchingOrganizationById,
    data: fetchOrganizationByIdData,
    refetch: getOrganizationById,
  } = useQuery(GET_ORGANIZATION_BY_ID, {
    client: coreClient,
    pollInterval: 0,
    variables: {
      organizationId,
    },
  });

  const { setChangeHistoryReferenceId } = useChangeHistory();
  useEffect(() => {
    if (organizationId) {
      setChangeHistoryReferenceId(organizationId);
      getOrganizationById({ organizationId });
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationId && (
      addDepartmentData
    || addJobData
    || addPositionData
    || createBillingData
    || updateOrganizationData
    || updateDepartmentData
    || updateJobData
    || updatePositionData
    || resetPositionData
    )) {
      getOrganizationById({ organizationId });
    }
  }, [
    addDepartmentData,
    addJobData,
    addPositionData,
    createBillingData,
    organizationId,
    updateDepartmentData,
    updateOrganizationData,
    updateJobData,
    updatePositionData,
    resetPositionData,
  ]);

  if (!fetchOrganizationByIdData?.organization) return <div />;
  const department = fetchOrganizationByIdData
    ?.organization?.departments?.find((d: DepartmentType) => d.departmentId === departmentId);
  const job = department?.jobs?.find((j: JobType) => j.jobId === jobId);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item sm={4} xs={12}>
        <OrganizationInformationCard organization={fetchOrganizationByIdData.organization} />
      </Grid>
      {department && (
        <Grid item sm={4} xs={12}>
          <DepartmentInformationCard department={department} />
        </Grid>
      )}
      {job && (
        <Grid item sm={4} xs={12}>
          <JobInformationCard job={job} />
        </Grid>
      )}
    </Grid>
  );
}

export function OrganizationDetails() {
  const { organizationId } = useParams();

  if (!organizationId) return <div />;
  return (
    <Wrapped organizationId={organizationId} />
  );
}
