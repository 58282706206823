import React, { useEffect, useState } from 'react';
import {
  Grid, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom';
import {
  useCore,
  CoreDialogProvider,
  useCoreDialog,
} from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddDepartmentDialog, MilimoSlice } from '../../reducers';

function Wrapped() {
  const {
    fetchStaffListData,
    addDepartment,
    addDepartmentData,
    addingDepartment,
    errorAddingDepartment,
  } = useCore();

  const dispatch = useDispatch();
  const addDepartmentDialogOpen = useSelector(
    (state: { milimo:MilimoSlice }) => state.milimo.addDepartmentDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  const [name, setName] = useState<string>();
  const [headOfDepartmentId, setHeadOfDepartmentId] = useState<string>();
  const { organizationId } = useParams();

  useEffect(() => {
    if (organizationId && name && headOfDepartmentId) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [organizationId, name, headOfDepartmentId]);

  useEffect(() => {
    setDialogOpen(addDepartmentDialogOpen);
    // eslint-disable-next-line
  }, [addDepartmentDialogOpen]);

  useEffect(() => {
    if (addDepartmentData) {
      dispatch(closeAddDepartmentDialog());
    }
    // eslint-disable-next-line
  }, [addDepartmentData]);

  const handleSubmitAddDepartment = () => {
    if (organizationId && headOfDepartmentId && name) {
      addDepartment({
        organizationId,
        headOfDepartmentId,
        name,
      });
    }
  };

  if (addingDepartment) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Add Department"
      submitAction={() => handleSubmitAddDepartment()}
      onClose={() => dispatch(closeAddDepartmentDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          {errorAddingDepartment && (
            <Typography variant="body2" color="error">{errorAddingDepartment.message}</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="department-name-tf"
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            margin="normal"
            label="Name"
            name="Name"
            value={name}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            onChange={(event, value) => setHeadOfDepartmentId(value?.staffId)}
            id="select-hod-autocomplete"
            options={fetchStaffListData
                    && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
            getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
            noOptionsText="No staff options: contact admin"
            renderInput={(params) => (
              <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                required
                label="Select Head of Department"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </CoreDialog>
  );
}

export function AddDepartmentDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
