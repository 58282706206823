/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl, TextField, Typography,
} from '@mui/material';
import { CoreDialogProvider, useCore, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddPositionDialog, MilimoSlice } from '../../reducers';

function Wrapped({ jobId }: { jobId: string }) {
  const {
    fetchStaffListData,
    addPosition,
    addPositionData,
    addingPosition,
    errorAddingPosition,
  } = useCore();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [staffId, setStaffId] = useState<string>();

  const dispatch = useDispatch();
  const addPositionDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.addPositionDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (!jobId
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [jobId]);

  useEffect(() => {
    setDialogOpen(addPositionDialogOpen);
  }, [addPositionDialogOpen]);

  const handleSubmitPosition = () => {
    if (jobId) {
      addPosition({
        jobId,
        staffId,
      });
    }
  };

  useEffect(() => {
    if (errorAddingPosition) {
      setErrorMessage('error adding position');
    }
  }, [errorAddingPosition]);

  useEffect(() => {
    if (addPositionData) {
      dispatch(closeAddPositionDialog());
    }
  }, [addPositionData]);

  if (addingPosition) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Add Position"
      submitAction={() => handleSubmitPosition()}
      onClose={() => dispatch(closeAddPositionDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <FormControl>
        <Container component="main" maxWidth="lg">
          <Autocomplete
            fullWidth
            onChange={(event, value) => setStaffId(value?.staffId)}
            sx={{ width: 300 }}
            id="select-staff-autocomplete"
            options={fetchStaffListData
              && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
            getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
            noOptionsText="No staff options: contact admin"
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} label="Select Employee" variant="outlined" />}
          />
        </Container>
      </FormControl>
    </CoreDialog>
  );
}

export function AddPositionDialog({ jobId }: { jobId: string }) {
  return (
    <CoreDialogProvider>
      <Wrapped jobId={jobId} />
    </CoreDialogProvider>
  );
}
