/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { CoreDialog } from '@think-zambia-foundation/components';
import {
  CoreDialogProvider, closeUpdateOrganizationDialog, useCore, useCoreDialog, CoreSlice, OrganizationType,
} from '@think-zambia-foundation/context';
import {
  Container, Grid, Typography, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetFormattedPhoneNumberString,
} from '@think-zambia-foundation/utils';

export interface UpdateOrganizationDialogProps {
  organization: OrganizationType
}

function Wrapped({ organization }: UpdateOrganizationDialogProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone1, setPhone1] = useState<string>();
  const [phone2, setPhone2] = useState<string>();
  const [addressLine1, setAddressLine1] = useState<string>();
  const [addressLine2, setAddressLine2] = useState<string>();
  const [city, setCity] = useState<string>();
  const [province, setProvince] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [country, setCountry] = useState<string>();
  const {
    errorUpdatingOrganization,
    updateOrganization,
    updatingOrganization,
  } = useCore();
  const dispatch = useDispatch();
  const updateOrganizationDialog = useSelector(
    (state: { core: CoreSlice }) => state.core.updateOrganizationDialogOpen,
  );

  const {
    dialogOpen,
    setDialogOpen,
  } = useCoreDialog();

  function resetDialog() {
    setName(undefined);
    setEmail(undefined);
    setPhone1(undefined);
    setPhone2(undefined);
    setAddressLine1(undefined);
    setAddressLine2(undefined);
    setCity(undefined);
    setProvince(undefined);
    setPostalCode(undefined);
    setCountry(undefined);
    dispatch(closeUpdateOrganizationDialog());
  }

  function handleUpdateOrganization() {
    updateOrganization({
      organizationId: organization.organizationId,
      name,
      email,
      phone1,
      phone2,
      addressLine1,
      addressLine2,
      city,
      province,
      postalCode,
      country,
    }).then(() => resetDialog());
  }

  useEffect(() => {
    setDialogOpen(updateOrganizationDialog);
  }, [updateOrganizationDialog]);

  useEffect(() => {
    setErrorMessage(errorUpdatingOrganization?.message);
  }, [errorUpdatingOrganization]);
  return (
    <CoreDialog
      modalTitle="Update Organization"
      dialogOpen={dialogOpen}
      submitAction={() => {
        handleUpdateOrganization();
      }}
      loading={updatingOrganization}
      onClose={() => resetDialog()}
    >
      <Container maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body2" color="error" align="center">{errorMessage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="organization-name-tf"
              onChange={(event) => setName(event.target.value)}
              variant="outlined"
              label="Name"
              name="Name"
              value={name || ''}
              placeholder={organization?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="organization-email-tf"
              onChange={(event) => setEmail(event.target.value)}
              variant="outlined"
              label="Email"
              name="Email"
              value={email || ''}
              placeholder={organization?.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="organization-phone1-tf"
              onChange={(event) => {
                const phoneInputNumber = event.target.value
                  .replace(/[^\d]/g, '')
                  .slice(3, 12);
                setPhone1(phoneInputNumber);
              }}
              value={
                // eslint-disable-next-line no-nested-ternary
                phone1 ? `${GetFormattedPhoneNumberString({ phone: phone1 })}` : organization?.phone1 ? '' : '+260 '
              }
              variant="outlined"
              label="Phone 1"
              name="Phone 1"
              placeholder={organization?.phone1 ? `${GetFormattedPhoneNumberString({ phone: organization?.phone1 })}` : '+260 '}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="organization-phone2-tf"
              variant="outlined"
              label="Phone 2"
              name="Phone 2"
              onChange={(event) => {
                const phoneInputNumber = event.target.value
                  .replace(/[^\d]/g, '')
                  .slice(3, 12);
                setPhone2(phoneInputNumber);
              }}
              value={
                // eslint-disable-next-line no-nested-ternary
                phone2 ? `${GetFormattedPhoneNumberString({ phone: phone2 })}` : organization?.phone2 ? '' : '+260 '
              }
              placeholder={organization?.phone2 ? `${GetFormattedPhoneNumberString({ phone: organization?.phone2 })}` : '+260 '}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="organization-addressLine1-tf"
              onChange={(event) => setAddressLine1(event.target.value)}
              variant="outlined"
              label="AddressLine 1"
              name="AddressLine 1"
              value={addressLine1 || ''}
              placeholder={organization?.address?.addressLine1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="organization-addressLine2-tf"
              onChange={(event) => setAddressLine2(event.target.value)}
              variant="outlined"
              label="AddressLine 2"
              name="AddressLine 2"
              value={addressLine2 || ''}
              placeholder={organization?.address?.addressLine2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="organization-city-tf"
              onChange={(event) => setCity(event.target.value)}
              variant="outlined"
              label="City"
              name="City"
              value={city || ''}
              placeholder={organization?.address?.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="organization-province-tf"
              onChange={(event) => setProvince(event.target.value)}
              variant="outlined"
              label="Province"
              name="Province"
              value={province || ''}
              placeholder={organization?.address?.province}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="organization-postalCode-tf"
              onChange={(event) => setPostalCode(event.target.value)}
              variant="outlined"
              label="Postal Code"
              name="Postal Code"
              value={postalCode || ''}
              placeholder={organization?.address?.postalCode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="organization-country-tf"
              onChange={(event) => setCountry(event.target.value)}
              variant="outlined"
              label="Country"
              name="Country"
              value={country || ''}
              placeholder={organization?.address?.country}
            />
          </Grid>
        </Grid>
      </Container>
    </CoreDialog>
  );
}

export default function UpdateOrganizationDialog({ organization }: UpdateOrganizationDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped organization={organization} />
    </CoreDialogProvider>
  );
}
