/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useCore, CoreDialogProvider, useCoreDialog, DepartmentType,
} from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useDispatch, useSelector } from 'react-redux';
import { closeUpdateDepartmentDialog, MilimoSlice } from '../../reducers';

function Wrapped({ department }: { department: DepartmentType }) {
  const {
    fetchStaffListData,
    updateDepartment,
    updateDepartmentData,
    updatingDepartment,
    errorUpdatingDepartment,
  } = useCore();

  const dispatch = useDispatch();
  const updateDepartmentDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.updateDepartmentDialogOpen,
  );

  const {
    dialogOpen,
    setDialogOpen,
  } = useCoreDialog();

  const [name, setName] = useState<string>();
  const [headOfDepartmentId, setHeadOfDepartmentId] = useState<string>();

  useEffect(() => {
    setDialogOpen(updateDepartmentDialogOpen);
    // eslint-disable-next-line
  }, [updateDepartmentDialogOpen]);

  useEffect(() => {
    if (updateDepartmentData) {
      dispatch(closeUpdateDepartmentDialog());
    }
    // eslint-disable-next-line
  }, [updateDepartmentData]);

  const handleSubmitUpdateDepartment = () => {
    if (department?.departmentId){
      updateDepartment({
        departmentId: department?.departmentId,
        headOfDepartmentId,
        name,
      });
    }
  };

  if (updatingDepartment) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Update Department"
      submitAction={() => handleSubmitUpdateDepartment()}
      onClose={() => dispatch(closeUpdateDepartmentDialog())}
      dialogOpen={dialogOpen}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          {errorUpdatingDepartment && (
            <Typography variant="body2" color="error">{errorUpdatingDepartment.message}</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="department-name-tf"
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            margin="normal"
            label="Name"
            name="Name"
            value={name || ''}
            placeholder={department.name}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            onChange={(event, value) => setHeadOfDepartmentId(value?.staffId)}
            id="select-hod-autocomplete"
            options={fetchStaffListData
              && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
            getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
            noOptionsText="No staff options: contact admin"
            renderInput={(params) => (
              <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Select Head of Department"
                variant="outlined"
                placeholder={`${department?.headOfDepartment?.firstName || ''} ${department?.headOfDepartment?.lastName || ''}`}
              />
            )}
          />
        </Grid>
      </Grid>
    </CoreDialog>
  );
}

export function UpdateDepartmentDialog(
  { department }: { department: DepartmentType },
) {
  return (
    <CoreDialogProvider>
      <Wrapped department={department} />
    </CoreDialogProvider>
  );
}
