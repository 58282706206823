/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { TableList } from '@think-zambia-foundation/components';
import {
  TableListProvider,
  useCore,
  useAuth,
  AuthProvider,
  CoreDialogProvider,
  CoreProvider,
  OrganizationType,
} from '@think-zambia-foundation/context';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { openCreateOrganizationDialog } from '../../reducers';
import { AddOrganizationDialog } from '../../components';

export interface OrganizationsTableListProps {
  organizations: OrganizationType[]
}

function OrganizationsTableList(
  { organizations }: OrganizationsTableListProps,
) {
  const dispatch = useDispatch();

  function fabActionCallBack() {
    dispatch(openCreateOrganizationDialog());
  }

  const { hasPermissionToClaim } = useAuth();
  if (!hasPermissionToClaim('organizationAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      <TableList
        data={organizations}
        tableMetadata={{
          href: '/organization',
          tableKey: 'organizationId',
          fabAction: {
            callback: fabActionCallBack,
            icon: <AddIcon />,
          },
          cells: [{
            key: 'organizationId',
            header: 'Organization ID',
            variant: 'id',
          }, {
            key: 'name',
            header: 'Name',
            variant: 'name',
          }],
        }}
      />
      <AddOrganizationDialog />
    </>
  );
}

function Wrapped() {
  const {
    getOrganizations,
    fetchingOrganizationsData,
    createOrganizationData,
  } = useCore();

  useEffect(() => {
    getOrganizations();
  }, [createOrganizationData]);

  return (
    <AuthProvider>
      <TableListProvider>
        <CoreDialogProvider>
          <OrganizationsTableList organizations={fetchingOrganizationsData?.organizations} />
        </CoreDialogProvider>
      </TableListProvider>
    </AuthProvider>
  );
}

export function Organizations() {
  return (
    <CoreProvider>
      <Wrapped />
    </CoreProvider>
  );
}
