/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl, TextField, Typography,
} from '@mui/material';
import { CoreDialogProvider, useCore, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';

import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { closeUpdateJobPositionDialog, MilimoSlice } from '../../reducers';

function Wrapped() {
  const {
    errorUpdatingPosition,
    fetchStaffListData,
    updatePosition,
    updatePositionData,
    updatingPosition,
  } = useCore();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [staffId, setStaffId] = useState<string>();

  const dispatch = useDispatch();
  const updateJobPositionDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.updateJobPositionDialogOpen,
  );
  const updatePositionReferenceId = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.updatePositionReferenceId,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (!updatePositionReferenceId
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [updatePositionReferenceId]);

  useEffect(() => {
    setDialogOpen(updateJobPositionDialogOpen);
  }, [updateJobPositionDialogOpen]);

  const handleSubmitPosition = () => {
    updatePosition({
      positionId: updatePositionReferenceId,
      staffId: staffId || '',
    });
  };

  useEffect(() => {
    if (errorUpdatingPosition) {
      setErrorMessage('error updating position');
    }
  }, [errorUpdatingPosition]);

  useEffect(() => {
    if (updatePositionData) {
      dispatch(closeUpdateJobPositionDialog());
    }
  }, [updatePositionData]);

  if (updatingPosition) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Update Position"
      submitAction={() => handleSubmitPosition()}
      onClose={() => dispatch(closeUpdateJobPositionDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <FormControl>
        <Container component="main" maxWidth="lg">
          <Autocomplete
            fullWidth
            onChange={(event, value) => setStaffId(value?.staffId)}
            sx={{ width: 300 }}
            id="select-staff-autocomplete"
            options={fetchStaffListData
              && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
            getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
            noOptionsText="No staff options: contact admin"
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Employee"
                variant="outlined"
              />
            )}
          />
        </Container>
      </FormControl>
    </CoreDialog>
  );
}

export function UpdatePositionDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
