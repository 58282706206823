/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  FormControl, Grid, TextField,
} from '@mui/material';
import { useCoreDialog, CoreDialogProvider, useCore } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useDispatch, useSelector } from 'react-redux';
import { closeCreateAssessmentDialog, MilimoSlice } from '../../reducers';

const ratings = [
  {
    label: '1 Poor',
    value: 1,
  },
  {
    label: '2 Average',
    value: 2,
  },
  {
    label: '3 Good',
    value: 3,
  },
  {
    label: '5 Very Good',
    value: 5,
  },
];

export interface CreateAssessmentDialogProps {
  approverId: string
  referenceId: string
}

function Wrapped({
  approverId,
  referenceId,
}: CreateAssessmentDialogProps) {
  const {
    dialogOpen,
    setDialogOpen,
    setSubmitDisabled,
    submitDisabled,
  } = useCoreDialog();

  const {
    creatingAssessment,
    createAssessment,
    createAssessmentData,
  } = useCore();

  const dispatch = useDispatch();
  const createAssessmentDialogOpen = useSelector(
    (state: { milimo: MilimoSlice }) => state.milimo.createAssessmentDialogOpen,
  );

  const [rating, setRating] = useState<number>(1);
  const [completedTasks, setCompletedTasks] = useState<string>();
  const [incompleteTasks, setIncompleteTasks] = useState<string>();
  const [improvementOpportunities, setImprovementOpportunities] = useState<string>();

  useEffect(() => {
    if (
      rating
      && completedTasks
      && incompleteTasks
      && improvementOpportunities
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [
    referenceId,
    approverId,
    rating,
    completedTasks,
    incompleteTasks,
    improvementOpportunities,
  ]);

  useEffect(() => {
    if (createAssessmentData) {
      dispatch(closeCreateAssessmentDialog());
    }
    // eslint-disable-next-line
  }, [createAssessmentData]);

  const handleSubmitAssessment = () => {
    if (completedTasks && incompleteTasks && improvementOpportunities) {
      createAssessment({
        referenceId,
        approverId,
        rating,
        completedTasks,
        incompleteTasks,
        improvementOpportunities,
        assessmentStatus: 'SUBMITTED', // TODO: Either create a vaiable for this or move to thinkzambia-express.
      });
    }
  };
  useEffect(() => {
    setDialogOpen(createAssessmentDialogOpen);
    // eslint-disable-next-line
  }, [createAssessmentDialogOpen]);

  useEffect(() => {
    if (createAssessmentData) {
      dispatch(closeCreateAssessmentDialog());
    }
  }, [createAssessmentData]);

  const CHARACTER_LIMIT = 250;

  return (
    <CoreDialog
      modalTitle="Assessment"
      submitAction={() => handleSubmitAssessment()}
      onClose={() => dispatch(closeCreateAssessmentDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={creatingAssessment}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <TextField
              label="Complete Tasks"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={(event) => setCompletedTasks(event.target.value)}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Incomplete Tasks"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={(event) => setIncompleteTasks(event.target.value)}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Improvement opportunities"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              onChange={(event) => setImprovementOpportunities(event.target.value)}
              inputProps={{
                maxLength: CHARACTER_LIMIT,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              // TODO: This should be an autocomplete to avoid the parseInt issue
              fullWidth
              select
              label="Add Rating"
              value={rating}
              onChange={(event) => setRating(parseInt(event.target.value, 10))}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
            >
              {ratings.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function CreateAssessmentDialog({
  approverId,
  referenceId,
}: CreateAssessmentDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped
        approverId={approverId}
        referenceId={referenceId}
      />
    </CoreDialogProvider>
  );
}
