/* eslint-disable react/prop-types */
import {
  Grid,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  CollapsibleCard,
  SmartTypography,
  TableList,
} from '@think-zambia-foundation/components';
import {
  useNavigate,
  // useLocation,
  useParams,
} from 'react-router-dom';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  DepartmentType, OrganizationType, openUpdateOrganizationDialog,
} from '@think-zambia-foundation/context';
import { useDispatch } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import { openAddDepartmentDialog } from '../../reducers';
import { AddDepartmentDialog, BillingList, CreateBillingDialog } from '../../components';
import UpdateOrganizationDialog from '../../components/UpdateOrganizationDialog/UpdateOrganizationDialog';

function OrganizationDetails({ organization }: { organization: OrganizationType }) {
  const dispatch = useDispatch();

  const handleUpdateOrganization = () => {
    if (organization && organization.organizationId) {
      dispatch(openUpdateOrganizationDialog());
    }
  };
  return (
    <>
      <CollapsibleCard
        title="Organization Information"
        actions={[
          {
            key: 'hhdjskhgt567890e9duchj',
            callback: () => handleUpdateOrganization(),
            icon: <EditTwoToneIcon />,
          },
        ]}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SmartTypography caption="Organization Name" text={organization.name} />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography caption="Email" variant="email" text={organization.email} />
            </Grid>
            <Grid item xs={6}>
              <SmartTypography
                caption="Phone 1"
                variant="phone"
                text={organization.phone1}
              />
            </Grid>
            <Grid item xs={6}>
              <SmartTypography
                caption="Phone 2"
                variant="phone"
                text={organization.phone2}
              />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography
                caption="Address"
                variant="name"
                text={`${organization?.address?.addressLine1 || ''}`}
              />
              {organization?.address?.addressLine2
              && <Typography variant="body2">{organization?.address?.addressLine2}</Typography>}
              {organization?.address?.city
              && <Typography variant="body2">{organization?.address?.city}</Typography>}
              {organization?.address?.province
              && <Typography variant="body2">{organization?.address?.province}</Typography>}
              {organization?.address?.postalCode
              && <Typography variant="body2">{organization?.address?.postalCode}</Typography>}
              {organization?.address?.country
              && <Typography variant="body2">{organization?.address?.country}</Typography>}
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>
      <UpdateOrganizationDialog
        organization={organization}
      />
    </>
  );
}

/*
function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}
*/

function Departments({ departments }: { departments: DepartmentType[] }) {
  const navigate = useNavigate();
  // const query = useQueryParam();
  // const departmentId = query.get('departmentId');
  const { organizationId } = useParams();

  const dispatch = useDispatch();

  function handleSelectDepartment(departmentId: string) {
    if (!departmentId) return;
    // e.preventDefault();
    navigate(`/organization/${organizationId}/?departmentId=${departmentId}`);
  }

  const handleAddDepartment = () => {
    if (organizationId) {
      dispatch(openAddDepartmentDialog());
    }
  };

  return (
    <CollapsibleCard
      title="Departments"
      actions={[
        {
          key: '22b60eaae17d4aef9f067907cc62b840',
          callback: () => handleAddDepartment(),
          icon: <AddTwoToneIcon />,
        },
      ]}
    >
      {departments && (
        <TableList
          data={departments || []}
          disableSearch
          hideCSVDownload
          hidePagination
          hideHeader
              // selected={selectedCell}
              // loading={loading}
          elevation={0}
          tableMetadata={{
            tableKey: 'departmentId',
            onClickCell: (departmentId) => {
              handleSelectDepartment(departmentId);
            },
            secondaryActions: [{
              key: '102d27cadf2249f5920b99445aeba08d',
              callback: () => {},
              icon: () => <ClearIcon color="primary" fontSize="small" />,
            }],
            cells: [{
              key: 'name',
              header: 'Name',
              variant: 'name',
            }],
          }}
        />
      )}
    </CollapsibleCard>
  );
}

export interface OrganizationInformationCardProps {
  organization: OrganizationType
}

export function OrganizationInformationCard(
  { organization }: OrganizationInformationCardProps,
) {
  if (!organization) return <div />;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <OrganizationDetails organization={organization} />
        </Grid>
        <Grid item xs={12}>
          <Departments departments={organization.departments} />
        </Grid>
        <Grid item xs={12}>
          <BillingList
            billingAccounts={organization.billing}
            organizationId={organization.organizationId}
          />
        </Grid>
      </Grid>
      <CreateBillingDialog />
      <AddDepartmentDialog />
    </>
  );
}
