/* eslint-disable react/prop-types */
import {
  CardContent, Grid,
} from '@mui/material';
import { CollapsibleCard, SmartTypography } from '@think-zambia-foundation/components';
import React from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { DepartmentType } from '@think-zambia-foundation/context';
import { useDispatch } from 'react-redux';
import { openUpdateDepartmentDialog } from '../../reducers';
import {
  UpdateDepartmentDialog,
} from '../../components';
import { JobList } from './JobList';

export interface DepartmentDetailsProps {
  department: DepartmentType
}

export function DepartmentDetails(
  { department }: DepartmentDetailsProps,
) {
  const dispatch = useDispatch();

  const handleUpdateDepartment = () => {
    if (department && department.departmentId) {
      dispatch(openUpdateDepartmentDialog());
    }
  };

  if (!department) return (<div />);
  return (
    <>
      <CollapsibleCard
        title="Department Information"
        actions={[
          {
            key: '7bc8c8d50e3c48b48e5ad3bbae5e2d13',
            callback: () => handleUpdateDepartment(),
            icon: <EditTwoToneIcon />,
          },
        ]}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SmartTypography caption="Name" text={department.name} />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography
                caption="Head of department"
                text={department.headOfDepartment ? `${department.headOfDepartment.firstName} ${department.headOfDepartment.lastName}` : ''}
                variant="name"
              />
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>
      <UpdateDepartmentDialog department={department} />
    </>
  );
}

export interface DepartmentInformationCardProps {
  department: DepartmentType
}

export function DepartmentInformationCard(
  { department }: DepartmentInformationCardProps,
) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <DepartmentDetails department={department} />
      </Grid>
      {department && (
        <Grid item xs={12}>
          <JobList
            jobs={department.jobs}
            referenceId={department.departmentId}
            organizationId={department?.organization?.organizationId}
          />
        </Grid>
      )}
    </Grid>
  );
}
